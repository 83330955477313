const emotions = [
  { key: 'joy', header: 'Joy' },
  { key: 'sadness', header: 'Sadness' },
  { key: 'analytical', header: 'Analytical' },
  { key: 'confident', header: 'Confident' },
  { key: 'tentative', header: 'Tentative' },
  { key: 'anger', header: 'Anger' },
  { key: 'fear', header: 'Fear' },
];

export default emotions;
