import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    width: '175px',
  },
  txt: {
    textAlign: 'center',
  },
  btnWrapper: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});

const DownloadModal = ({
  analysisIds = [],
  buttonClassName,
  fileName,
  csvUrl,
  imageDataUrl,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleShow = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        disabled={analysisIds.length === 0}
        className={buttonClassName}
      >
        Download
      </Button>

      <Modal
        show={isOpen}
        onHide={handleClose}
        centered
        dialogClassName={classes.modal}
      >
        <Modal.Body className={classes.container}>
          <div className={classes.txt}>
            What type of file would you like to download?
          </div>
          <div className={classes.btnWrapper}>
            <a href={csvUrl} download={`${fileName}.csv`}>
              <Button variant="outline-primary" onClick={handleClose}>
                CSV
              </Button>
            </a>
            <a href={imageDataUrl} download={`${fileName}.jpeg`}>
              <Button variant="outline-primary" onClick={handleClose}>
                JPEG
              </Button>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DownloadModal;
