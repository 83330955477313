import React, { useState, useEffect, useRef } from 'react';
import { Chart, Bar, defaults as chartDefaults } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import generateCsv from 'csv-stringify/lib/sync';
import Loading from './Loading';
import ErrorAlert from './ErrorAlert';
import whiteChartBackground from '../utils/chartjs-white-background';
import EMOTIONS from './emotions';
import ShareAnalysesModal from './ShareAnalysesModal';
import DownloadModal from './DownloadModal';

Chart.pluginService.register(whiteChartBackground);
chartDefaults.global.defaultFontColor = 'black';

function SingleChart({ analysisIds, title, fileName }) {
  const [report, setReport] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    fetch('/api/analyze-tone', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ analysisIds }),
      credentials: 'include',
    })
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          setReport(body);
        } else {
          const text = await res.text();
          setError(text || res.statusText);
        }
      })
      .catch((error) => setError(error.message))
      .finally(() => setIsAnalyzing(false));
  }, [analysisIds]);

  const chartRef = useRef(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);

  const handleChartChange = () => {
    if (!imageDataUrl && chartRef.current) {
      const dataUrl = chartRef.current.chartInstance.canvas.toDataURL(
        'image/jpeg'
      );
      setImageDataUrl(dataUrl);
    }
  };

  const [csvUrl, setCsvUrl] = useState(null);

  useEffect(() => {
    if (report) {
      const fileData = generateCsv([report.vector], {
        columns: EMOTIONS,
        header: true,
      });
      const file = new Blob([fileData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(file);
      setCsvUrl(url);
    }
    return () => {
      if (csvUrl) window.URL.revokeObjectURL(csvUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  if (isAnalyzing) return <Loading />;
  if (error) return <ErrorAlert message={error} />;

  return (
    <>
      <div className="btn-group float-right mt-2 mr-2">
        <DownloadModal
          analysisIds={analysisIds}
          fileName={fileName}
          csvUrl={csvUrl}
          imageDataUrl={imageDataUrl}
        />
        <ShareAnalysesModal analysisIds={analysisIds} />
      </div>

      <div className="chart">
        <Bar
          data={{
            labels: EMOTIONS.map((one) => one.header),
            datasets: [
              {
                label: 'Tone',
                backgroundColor: 'rgba(13, 131, 186, 0.75)',
                borderColor: 'rgba(13, 131, 186, 1)',
                fontColor: 'black',
                borderWidth: 2,
                data: EMOTIONS.map((one) => report.vector[one.key].toFixed(2)),
              },
            ],
          }}
          options={{
            responsive: true,
            aspectRatio: 1.91,
            legend: {
              display: true,
              labels: {
                fontColor: 'black',
              },
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 1,
                    min: 0,
                    stepSize: 0.2,
                  },
                },
              ],
            },
            animation: {
              onComplete: handleChartChange,
            },
          }}
          ref={chartRef}
        />
      </div>
    </>
  );
}

export default SingleChart;
