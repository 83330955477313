import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { NavLink } from 'react-router-dom';
import SortUpIcon from './icons/SortUp';
import SortDownIcon from './icons/SortDown';
import ArrowDownIcon from './icons/ArrowDown';
import indexByKey from '../utils/index-by-key';
import Loading from './Loading';

const getValue = (row, column) => {
  if (column.getValue) return column.getValue(row);
  switch (column.type) {
    case 'date':
    case 'datetime':
      return row[column.name] ? new Date(row[column.name]) : null;
    default:
      return row[column.name] == null ? null : row[column.name];
  }
};

const getFormattedValue = (row, column) => {
  const value = getValue(row, column);
  if (column.formatValue) return column.formatValue(value);
  switch (column.type) {
    case 'date':
      return value ? value.toLocaleDateString() : null;
    case 'datetime':
      return value ? value.toLocaleString() : null;
    case 'boolean':
      return row[column.name] === true ? 'yes' : 'no';
    default:
      return value;
  }
};

const applyQuery = (columns, rows, query) => {
  if (!query) return rows;
  const queryRegExp = new RegExp(query.toLowerCase(), 'i');
  return rows.filter((row) =>
    columns.some((column) =>
      queryRegExp.test(String(getFormattedValue(row, column)))
    )
  );
};

const applyFilter = (columnsByName, rows, filter) => {
  return rows.filter((row) => {
    return Object.entries(filter).every(([columnName, query]) => {
      if (!query) return true;
      const column = columnsByName[columnName];
      const queryRegExp = new RegExp(query.toLowerCase(), 'i');
      return queryRegExp.test(String(getFormattedValue(row, column)));
    });
  });
};

const applySort = (columnsByName, rows, sort) => {
  return [...rows].sort((a, b) => {
    return Object.entries(sort).reduce((result, [columnName, order]) => {
      if (order === 0) return result;
      const column = columnsByName[columnName];
      const aValue = getValue(a, column);
      const bValue = getValue(b, column);
      return result || (aValue < bValue ? -1 : aValue > bValue ? 1 : 0) * order;
    }, 0);
  });
};

const Formatter = ({ row, column }) => {
  const formattedValue = getFormattedValue(row, column);
  const CustomFormatter = column.Formatter;
  if (CustomFormatter)
    return <CustomFormatter formattedValue={formattedValue} />;
  return <span>{formattedValue}</span>;
};

const Editor = ({ row, column, onChange }) => {
  const value = getValue(row, column);
  switch (column.type) {
    case 'boolean':
      return (
        <select
          value={value || ''}
          onChange={(event) => onChange(event.target.value === 'true')}
          className="form-control form-control-sm"
        >
          <option value="false">no</option>
          <option value="true">yes</option>
        </select>
      );
    default:
      return (
        <input
          type={column.type || 'text'}
          value={value || ''}
          onChange={(event) => onChange(event.target.value)}
          className="form-control form-control-sm"
          autoComplete={column.type === 'password' ? 'new-password' : 'off'}
        />
      );
  }
};

const useStyles = createUseStyles({
  table: {
    '& td, & th': {
      whiteSpace: 'nowrap',
    },
  },
  row: {
    '&:hover': {
      backgroundColor: 'rgba(230, 231, 232, 0.4)',
    },
  },
  checkboxContainer: {
    paddingLeft: '40px!important',
  },
  checkbox: {
    position: 'unset',
  },
});

function Table({
  columns,
  rows,
  onRowAdd,
  onRowDelete,
  onRowEdit,
  selected,
  onSelect,
  isLoading,
  showFilters = true,
}) {
  const columnsByName = indexByKey(columns, 'name');

  const classes = useStyles();

  const [query, setQuery] = useState('');

  const [filter, setFilter] = useState({}); // { name: 'lan', email: 'lanrii' }
  const handleFilterChange = (columnName, query) => {
    setFilter((filter) => ({
      ...filter,
      [columnName]: query,
    }));
  };

  const [sort, setSort] = useState({}); // { id: 1, name: -1  }
  const handleSortChange = (columnName) => {
    setSort((sort) => ({
      ...sort,
      [columnName]:
        sort[columnName] === 1 ? -1 : sort[columnName] === -1 ? 0 : 1,
    }));
  };

  const [limit, setLimit] = useState(20);
  const handleShowMore = () => {
    setLimit((limit) => Math.min(limit * 2, limit + 120));
  };

  let visibleRows = [...rows];
  visibleRows = applyQuery(columns, visibleRows, query);
  visibleRows = applyFilter(columnsByName, visibleRows, filter);
  visibleRows = applySort(columnsByName, visibleRows, sort);

  const handleSelect = (id, checked) => {
    onSelect(
      checked ? [...selected, id] : selected.filter((one) => one !== id)
    );
  };

  const [drafts, setDrafts] = useState([]);
  const handleDraftAdd = () => {
    setDrafts((drafts) => [...drafts, {}]);
  };
  const handleDraftChange = (index, columnName, value) => {
    setDrafts((drafts) =>
      drafts.map((one, onesIndex) =>
        onesIndex === index ? { ...one, [columnName]: value } : one
      )
    );
  };
  const handleDraftCancel = (index) => {
    setDrafts((drafts) =>
      drafts.map((one, onesIndex) => (onesIndex === index ? null : one))
    );
  };
  const handleDraftSave = (index) => {
    onRowAdd(drafts[index]).then((hasSaved) => {
      if (hasSaved) handleDraftCancel(index);
    });
  };

  const [edits, setEdits] = useState({});
  const handleEditStart = (row) => {
    setEdits((edits) => ({
      ...edits,
      [row.id]: { ...row },
    }));
  };
  const handleEditChange = (id, columnName, value) => {
    setEdits((edits) => ({
      ...edits,
      [id]: { ...edits[id], [columnName]: value },
    }));
  };
  const handleEditCancel = (id) => {
    setEdits((edits) => {
      const nextEdits = { ...edits };
      delete nextEdits[id];
      return nextEdits;
    });
  };
  const handleEditSave = (id) => {
    onRowEdit(edits[id]).then((hasSaved) => {
      if (hasSaved) handleEditCancel(id);
    });
  };

  if (isLoading) return <Loading />;

  return (
    <>
      {showFilters && (
        <div className="row">
          <div className="col-sm mb-3">
            <input
              type="search"
              placeholder="Search..."
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              className="form-control"
            />
          </div>
        </div>
      )}

      <div className="table-responsive">
        <table className={`table ${classes.table}`}>
          <thead>
            <tr>
              {onSelect && <th />}
              {columns.map((column) => (
                <th key={column.name}>
                  {column.title}
                  <button
                    type="button"
                    className={`btn ${
                      sort[column.name] ? 'btn-primary' : 'btn-default'
                    } mx-2 py-0 px-1`}
                    onClick={() => handleSortChange(column.name)}
                  >
                    {sort[column.name] === -1 ? (
                      <SortDownIcon />
                    ) : (
                      <SortUpIcon />
                    )}
                  </button>
                </th>
              ))}
              {onRowAdd && <th style={{ minWidth: 140 }} />}
            </tr>
            {showFilters && (
              <tr>
                {onSelect && <td />}
                {columns.map((column) => (
                  <td className="px-2 py-2" key={column.name}>
                    {column.isFilterable !== false && (
                      <input
                        type={column.filterType || column.type || 'text'}
                        value={filter[column.name] || ''}
                        onChange={(event) =>
                          handleFilterChange(column.name, event.target.value)
                        }
                        placeholder="Filter..."
                        className="form-control form-control-sm"
                      />
                    )}
                  </td>
                ))}
                {onRowAdd && (
                  <td className="px-2 py-2">
                    <button
                      className="btn btn-success btn-sm w-100"
                      onClick={handleDraftAdd}
                    >
                      Add
                    </button>
                  </td>
                )}
              </tr>
            )}
          </thead>
          <tbody>
            {drafts.map(
              (draft, index) =>
                draft && (
                  <tr className={classes.row} key={index}>
                    {columns.map((column) => (
                      <td className="px-2 py-2" key={column.name}>
                        {column.isInitializable !== false && (
                          <Editor
                            row={drafts[index]}
                            column={column}
                            onChange={(value) =>
                              handleDraftChange(index, column.name, value)
                            }
                          />
                        )}
                      </td>
                    ))}
                    <td className="px-2 py-2">
                      <div className="btn-group w-100">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleDraftSave(index)}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={() => handleDraftCancel(index)}
                        >
                          Cancel
                        </button>
                      </div>
                    </td>
                  </tr>
                )
            )}
            {visibleRows.slice(0, limit).map((row) => (
              <tr className={classes.row} key={row.id}>
                {onSelect && (
                  <td className={classes.checkboxContainer}>
                    <input
                      type="checkbox"
                      value={selected.includes(row.id)}
                      onChange={(event) =>
                        handleSelect(row.id, event.target.checked)
                      }
                      className={`form-check-input ${classes.checkbox}`}
                    />
                  </td>
                )}
                {columns.map((column) =>
                  edits[row.id] && column.isEditable !== false ? (
                    <td key={column.name} className="px-2 py-2">
                      <Editor
                        row={edits[row.id]}
                        column={column}
                        onChange={(value) =>
                          handleEditChange(row.id, column.name, value)
                        }
                      />
                    </td>
                  ) : (
                    <td key={column.name}>
                      <Formatter row={row} column={column} />
                    </td>
                  )
                )}
          
                {(onRowEdit || onRowDelete) && (
                  <td className="px-2 py-2">
                    {onRowEdit && edits[row.id] ? (
                      <div className="btn-group w-100">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleEditSave(row.id)}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={() => handleEditCancel(row.id)}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div className="btn-group w-100">
                        {onRowEdit && (
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleEditStart(row)}
                          >
                            Edit
                          </button>
                        )}
                        {onRowDelete && (
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => onRowDelete(row)}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    )}
                  </td>
                )}
              </tr>
              
            ))}
                {!showFilters &&  <NavLink
                   to="/analyses"
                  className="btn btn-link"
                  style={{whiteSpace: 'nowrap'}}
                  onClick={handleShowMore}
                >
                  View More
                </NavLink>}
          </tbody>
        </table>
      </div>
      {visibleRows.length > limit && (
        <button type="button" className="btn btn-link" onClick={handleShowMore}>
          Show more
          <ArrowDownIcon />
        </button>
      )}
    </>
  );
}

export default Table;
