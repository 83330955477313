import React, { useState, useEffect } from 'react';
import { Router, Route, NavLink, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { createUseStyles } from 'react-jss';
import 'bootstrap/dist/css/bootstrap.css';
import AnalyzePage from '../pages/AnalyzePage';
import ArchivePage from './ArchivePage';
import AnalysisResultPage from './AnalysisResultPage';
import LoginPage from './LoginPage';
import AdminPage from './AdminPage';
import AnalyzeLandingPage from '../pages/AnalyzeLandingPage';

const history = createBrowserHistory();

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-TQYSXP4NF9');
  // Record the initial GA pageview.
  ReactGA.pageview(window.location.pathname + window.location.search);
  // Whenever the current location changes, record a GA pageview.
  history.listen((location) => {
    ReactGA.set({ page: location.pathname + location.search });
    ReactGA.pageview(location.pathname + location.search);
  });
}
const useStyles = createUseStyles({
  nav: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '16px 42px',
    backgroundColor: '#b6203a',
    color: 'white',
  },
  '@media (max-width: 767px)': {
    nav: {
      flexDirection: 'column',
      padding: '14px 18px',
    },
  },
  logo: {
    height: 50,
    margin: 10,
  },
  link: {
    display: 'inline-block',
    padding: '12px 24px',
    color: 'white',
    textDecoration: 'none',
    '&:hover, :visited, :active': {
      color: 'white',
      opacity: 0.8,
      textDecoration: 'none',
    },
  },
  activeLink: {
    borderBottom: '2px solid white',
  },
  outlinedButton: {
    background: 'none',
    color: 'inherit',
    padding: '8px 20px',
    border: '2px solid #ebeaed',
    borderRadius: '22px',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
});

function App() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetch('/api/auth/current', { credentials: 'include' })
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          setUser(body);
        } else {
          throw Error(res.statusText);
        }
      })
      .catch((error) => console.log(error.message))
      .finally(() => setIsLoading(false));
  }, []);

  const handleLogout = () => {
    fetch('/api/auth/logout', { credentials: 'include' })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = '/';
          // setUser(null);
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((error) => alert(error.message));
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({ userId: user && user.id });
    }
  }, [user]);

  if (isLoading) return null;

  return (
    <Router history={history}>
      <Helmet>
        <title>People Rocket Semantic & Tonal Analysis</title>
        <meta
          property="og:title"
          content="People Rocket Semantic & Tonal Analysis"
        />
        <meta property="og:image" content="/share-thumbnail.png" />
      </Helmet>
      {user ? (
        <>
          <nav className={classes.nav}>
            <img
              src="/logo-white.png"
              alt="People Rocket"
              className={classes.logo}
            />
            <div className="flex-grow-1" />
            <div className="order-3 order-md-2 mx-3">
              <NavLink
                to="/analyze"
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                Analyze
              </NavLink>
              <NavLink
                to="/analyses"
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                Archive
              </NavLink>
              {user.isAdmin && (
                <NavLink
                  to="/admin"
                  className={classes.link}
                  activeClassName={classes.activeLink}
                >
                  Admin
                </NavLink>
              )}
            </div>
            <div className="py-2 order-2 order-md-3 mx-3">
              <span className="mr-3">
                {user.firstName} {user.lastName}
              </span>
              <button onClick={handleLogout} className={classes.outlinedButton}>
                Log out
              </button>
            </div>
          </nav>
          <main>
            <Switch>
              <Route exact path="/" component={AnalyzeLandingPage} />
              <Route
                exact
                path="/analyze/used-to-think"
                component={AnalyzePage}
              />
              <Route
                exact
                path="/analyze/regular-text"
                component={AnalyzePage}
              />
              <Route exact path="/analyses" component={ArchivePage} />
              <Route path="/analyses/:id" component={AnalysisResultPage} />
              {user.isAdmin && (
                <Route exact path="/admin" component={AdminPage} />
              )}
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </main>
        </>
      ) : (
        <Switch>
          <Route
            path="/analyses/:id"
            render={(props) => (
              <>
                <nav className={`${classes.nav} justify-content-center`}>
                  <a href="/">
                    <img
                      src="/logo-white.png"
                      alt="People Rocket"
                      className={classes.logo}
                    />
                  </a>
                </nav>
                <main className="container py-4">
                  <AnalysisResultPage {...props} />
                </main>
              </>
            )}
          />
          <Route
            render={(props) => <LoginPage {...props} onUserChange={setUser} />}
          />
        </Switch>
      )}
    </Router>
  );
}

export default App;
