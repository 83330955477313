import React from 'react';
import './styles/index.scss';
import FileImage from './styles/fileImage.png';
import fileSaver from 'file-saver';

const UsedToThink = ({ fileInput, files, setFiles }) => {
  const downloadTemplate = async () => {
    console.log('click');
    try {
      const res = await fetch(`/api/analyses/download-template`, {
        credentials: 'include',
        headers: { 'Content-Type': 'text/csv' },
      });
      const data = await res.blob();
      fileSaver.saveAs(data, 'I_used_to_think_template.csv');
    } catch (error) {
      alert(error.message);
    }
  };

  const handleFileChange = (addedFiles) => {
    const reg = /\.csv$/;
    if (files.length >= 10) return;
    const newFiles = Object.values(addedFiles);
    setFiles((curFiles) => [
      ...curFiles,
      ...newFiles.filter((file) => file.name.match(reg)),
    ]);
  };

  const deletFile = (fileName) => {
    const newFiles = files.filter((file) => file.name !== fileName);
    setFiles(newFiles);
  };

  return (
    <div className="used-to-think">
      <div className="download-template-button" onClick={downloadTemplate}>
        <div className="download-template-button__text">
          Download blank template to complete
        </div>
        <img
          src={FileImage}
          className="download-template-button__image"
          alt=""
        />
      </div>
      <div className="separator" />
      <div className="form-group" style={{ display: 'flex' }}>
        {files && (
          <div className="choosed-files">
            {files.map((file, index) => (
              <div
                className="choosed-files__file-block"
                key={`${file.name}${index}`}
              >
                <p onClick={() => deletFile(file.name)}>x</p>
                <p>{file.name}</p>
              </div>
            ))}
          </div>
        )}
        <div className="custom-file">
          <input
            type="file"
            ref={fileInput}
            onChange={(event) => handleFileChange(event.target.files)}
            accept=".csv"
            className="custom-file-input"
            id="customFile"
            multiple
          />
          <label className="custom-file-label" htmlFor="customFile">
            Choose file (max 10)
          </label>
        </div>
        {/* <GooglePicker
                mimeTypes="text/csv,text/plain"
                onChange={handleGoogleFileChange}
              /> */}
      </div>
    </div>
  );
};

export default UsedToThink;
