import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ShareAnalysesModal from './ShareAnalysesModal';
import Table from './Table';
import DeleteIcon from './icons/Delete';
import qs from "qs"
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    padding: '20px 100px',
  },
  deleteIcon: {
    height: '38px',
    width: '38px',
    cursor: 'pointer',
    marginLeft: '20px',
  },
});

const tableColumns = [
  {
    name: 'id',
    title: 'ID',
  },
  {
    name: 'uploadedAt',
    title: 'Upload Date',
    type: 'date',
    filterType: 'text',
  },
  {
    name: 'userName',
    title: 'User Submitted',
    getValue: (row) => `${row.uploadedBy.firstName} ${row.uploadedBy.lastName}`,
  },
  {
    name: 'clientOrCohortName',
    title: 'Client / Cohort Name',
  },
  {
    name: 'dataSetId',
    title: 'Data Set ID',
  },
  {
    name: 'dataSetName',
    title: 'Data Set Name',
  },
  {
    name: 'dataSetCollectionDate',
    title: 'Data Set Collection Date',
    type: 'date',
    filterType: 'text',
  },
  {
    name: 'analysisType',
    title: 'Analysis Type',
    getValue: (row) => (row.format === '.csv' ? 'IUTT BNIT' : 'Regular'),
  },
  {
    name: 'facilitator',
    title: 'Facilitator',
  },
  {
    name: 'tags',
    title: 'Tags',
    getValue: (row) => row.tags && row.tags.map((one) => one.value).join(' '),
  },
];

function ArchivePage() {
  const classes = useStyles();
  const [analyses, setAnalyses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [selected, setSelected] = useState([]);


  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    fetch('/api/analyses', { credentials: 'include' })
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          if (isActive) {
            setAnalyses(body);
            setIsLoading(false);
          }
        }
      })
      .catch((error) => alert(error.message));
    return () => {
      isActive = false;
    };
  }, [
    isLoadingDelete
  ]);

  const deleteRow = () => {
    let isActive = true;
    setIsLoadingDelete(true);
    fetch(`/api/analyses/deletion?${qs.stringify({ id: selected })}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(async (res) => {
        if (res.status === 200) {
          if (isActive) {
            setSelected([])
            setIsLoadingDelete(false);
          }
        }
      })
      .catch((error) => alert(error.message));
      return () => {
        isActive = false;
      };
  };

  return (
    <div className={classes.container}>
      <div className="row">
        <h2 className="col-sm mb-3">Archive</h2>
        <div className="col-sm mb-3">
          <div className="btn-group float-right">
            <Link
              to={
                selected.length === 0
                  ? '#nowhere'
                  : selected.length === 1
                  ? `/analyses/${selected[0]}`
                  : `/analyses/aggregate?ids=${selected.join()}`
              }
              className={classNames('btn', 'btn-primary', {
                disabled: selected.length === 0,
              })}
            >
              View
            </Link>
            <ShareAnalysesModal analysisIds={selected} />
            <DeleteIcon
              onClick={deleteRow}
              className={classes.deleteIcon}
              fill={selected.length === 0 ? '#d6d6d6' : 'black'}
            />
          </div>
        </div>
      </div>
      <Table
        columns={tableColumns}
        rows={analyses}
        selected={selected}
        onSelect={setSelected}
        isLoading={isLoading}
      />
    </div>
  );
}

export default ArchivePage;
