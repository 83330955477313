import React, { useEffect, useState } from 'react';
import { Modal, Button, InputGroup } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import qs from 'qs';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { createUseStyles } from 'react-jss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Alert } from 'react-bootstrap';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  linksContainer: {
    height: '100px',
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkedinImg: {
    height: '60px',
    width: '60px',
    cursor: 'pointer',
  },
  urlImg: {
    height: '55px',
    width: '55px',
  },
  urlContainer: {
    height: '60px',
    width: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#d6d6d6',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
  },
  separator: {
    width: '2px',
    height: '80%',
    backgroundColor: '#d6d6d6',
  },
});

function ShareAnalysesModal({ analysisIds = [], buttonClassName }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [message, setMessage] = useState(null);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [timer, setTimer] = useState();

  const availableOptions = options.filter(
    (option) =>
      option.id !== currentUser.id &&
      !selectedUsers.some((user) => user.id === option.id)
  );

  const handleMessageClose = () => {
    setIsMessageVisible(false);
    setMessage(null);
  };

  useEffect(() => {
    fetch('/api/auth/current', { credentials: 'include' })
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          setCurrentUser(body);
        } else {
          throw Error(res.statusText);
        }
      })
      .catch((error) => console.log(error.message));
  }, []);

  const handleLinkedInShare = async () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${escape(
        window.location.href
      )}`,
      '_blank'
    );
    handleClose();
  };

  const handleLinkCopied = async () => {
    setMessage('Copied Link to Clipboard');
    setIsMessageVisible(true);
  };

  useEffect(() => {
    if (!isMessageVisible) return;
    let isActive = true;
    let timer = setTimeout(() => {
      if (isActive) handleMessageClose();
      if (message !== 'Copied Link to Clipboard') handleClose();
    }, 3000);
    setTimer(timer);
    return () => {
      isActive = false;
    };
  }, [isMessageVisible]);

  const handleSearch = (query) => {
    setIsLoading(true);
    fetch(`/api/users?${qs.stringify({ search: query, page: 0, limit: 10 })}`)
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          setOptions(body);
          setIsLoading(false);
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((error) => alert(error.message));
  };

  const handleShow = () => setIsOpen(true);

  const handleClose = () => {
    setSelectedUsers([]);
    setIsOpen(false);
    handleMessageClose();
    clearTimeout(timer);
  };

  const handleSubmit = () => {
    Promise.all(
      analysisIds.map((analysisId) =>
        Promise.all(
          selectedUsers.map((user) =>
            fetch(`/api/analyses/${analysisId}/share`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ userId: user.id }),
              credentials: 'include',
            }).then(async (res) => {
              if (res.status !== 200)
                throw new Error((await res.text()) || res.statusText);
            })
          )
        )
      )
    )
      .then(() => {
        setIsMessageVisible(true);
        setMessage('Share successful!');
      })
      .catch((error) => alert(error));
  };

  return (
    <>
      <Button
        variant="danger"
        onClick={handleShow}
        disabled={analysisIds.length === 0}
        className={buttonClassName}
      >
        Share
      </Button>

      <Modal show={isOpen} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Share with other users</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.container}>
          {currentUser ? (
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <img src={require('./icons/email.svg')} alt="" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <AsyncTypeahead
                id="user-select"
                placeholder="Email"
                selected={selectedUsers}
                onChange={setSelectedUsers}
                isLoading={isLoading}
                options={availableOptions}
                labelKey={(user) =>
                  `${user.firstName} ${user.lastName} (${user.email})`
                }
                onSearch={handleSearch}
                minLength={1}
                maxResults={5}
                multiple
                useCache={false}
                s
                filterBy={() => true}
              />
            </InputGroup>
          ) : (
            <div />
          )}
          <div>or</div>
          <div className={classes.linksContainer}>
            <CopyToClipboard
              text={window.location.href}
              onCopy={handleLinkCopied}
            >
              <div className={classes.linkWrapper}>
                <div className={classes.urlContainer}>
                  <img
                    alt=""
                    className={classes.urlImg}
                    src={require('./icons/link.svg')}
                  />
                </div>
                <div>Share a URL</div>
              </div>
            </CopyToClipboard>
            <div className={classes.separator} />
            <div className={classes.linkWrapper} onClick={handleLinkedInShare}>
              <img
                alt=""
                className={classes.linkedinImg}
                src={require('./icons/linkedin.svg')}
              />
              <div>Share on Linkedin</div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Share
          </Button>
        </Modal.Footer>
        {message && (
          <Alert
            show={isMessageVisible}
            variant="success"
            onClose={handleMessageClose}
            dismissible
            className={classes.alert}
          >
            {message}
          </Alert>
        )}
      </Modal>
    </>
  );
}

export default ShareAnalysesModal;
