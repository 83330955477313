import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Helmet } from 'react-helmet';
import useQuery from './use-query';
import Loading from './Loading';
import ErrorAlert from './ErrorAlert';
import NotFound from './NotFound';
import SingleChart from './SingleChart';
import SplitChart from './SplitChart';

const useStyles = createUseStyles({
  table: {
    '& td, & th': {
      whiteSpace: 'nowrap',
    },
  },
  chartWindow: {
    position: 'relative',
    width: '100%',
    overflowX: 'auto',
  },
  chartContainer: {
    minWidth: 750,
    '& .canvas': {
      position: 'absolute',
      left: 0,
      top: 0,
      pointerEvents: 'none',
    },
  },
});

function AnalysisResultPage() {
  const classes = useStyles();
  const params = useParams();
  const query = useQuery();

  const analysisIdsString =
    params.id === 'aggregate' ? query.get('ids') : params.id;
  const analysisIds = analysisIdsString.split(',');

  const [analyses, setAnalyses] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    setError(null);
    Promise.all(
      analysisIds.map((analysisId) =>
        fetch(`/api/analyses/${analysisId}`, { credentials: 'include' }).then(
          (res) => {
            if (res.status === 200) {
              return res.json();
            } else {
              throw new Error(res.statusText);
            }
          }
        )
      )
    )
      .then((analyses) => setAnalyses(analyses))
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisIdsString]);

  if (isLoading) return <Loading />;
  if (error) return <ErrorAlert message={error} />;
  if (analyses == null || analyses.length === 0) return <NotFound />;

  let title, fileName;
  if (analyses.length > 1) {
    title = 'Aggregate';
    fileName = `aggregate-${analysisIds.join('-')}`;
  } else {
    title = `${analyses[0].clientOrCohortName} ${analyses[0].dataSetName}`;
    fileName = title.replace(/[^a-z0-9]/gi, '-').toLowerCase();
  }

  const Chart = analyses.every((one) => one.format === '.csv')
    ? SplitChart
    : SingleChart;

  return (
    <div className="container py-4">
      <Helmet>
        <title>{title} | People Rocket Semantic & Tonal Analysis</title>
        <meta
          property="og:title"
          content={`${title} | People Rocket Semantic & Tonal Analysis`}
        />
        <meta property="og:image" content="/share-thumbnail.png" />
      </Helmet>
      <h2>{title}</h2>
      <div className="table-responsive my-2">
        <table className={`table ${classes.table}`}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Upload Date</th>
              <th>User Submitted</th>
              <th>Client / Cohort Name</th>
              <th>Data Set ID</th>
              <th>Data Set Name</th>
              <th>Data Set Collection Date</th>
              <th>Analysis Type</th>
              <th>Facilitator</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {analyses.map((analysis) => (
              <tr className={classes.row} key={analysis.id}>
                <td>{analysis.id}</td>
                <td>{new Date(analysis.uploadedAt).toLocaleDateString()}</td>
                <td>
                  {analysis.uploadedBy.firstName} {analysis.uploadedBy.lastName}
                </td>
                <td>{analysis.clientOrCohortName}</td>
                <td>{analysis.dataSetId}</td>
                <td>{analysis.dataSetName}</td>
                <td>
                  {new Date(
                    analysis.dataSetCollectionDate
                  ).toLocaleDateString()}
                </td>
                <td>{analysis.format === '.csv' ? 'IUTT BNIT' : 'Regular'}</td>
                <td>{analysis.facilitator}</td>
                <td>
                  {analysis.tags &&
                    analysis.tags.map((one) => one.value).join(' ')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.chartWindow}>
        <div className={classes.chartContainer}>
          <Chart analysisIds={analysisIds} title={title} fileName={fileName} />
        </div>
      </div>
    </div>
  );
}

export default AnalysisResultPage;
