import React, { useState, useEffect } from 'react';
import AnalyzeImage from "./images/analyzeImage.png"
import qs from "qs"
import { Link } from 'react-router-dom';
import ShareAnalysesModal from '../../components/ShareAnalysesModal';
import classNames from 'classnames';
import Tabel from "../../components/Table"
import { useHistory } from "react-router-dom" 
import "./styles/index.scss";

const tableColumns = [
  {
    name: 'id',
    title: 'ID',
  },
  {
    name: 'uploadedAt',
    title: 'Upload Date',
    type: 'date',
    filterType: 'text',
  },
  {
    name: 'userName',
    title: 'User Submitted',
    getValue: (row) => `${row.uploadedBy.firstName} ${row.uploadedBy.lastName}`,
  },
  {
    name: 'clientOrCohortName',
    title: 'Client / Cohort Name',
  },
  {
    name: 'dataSetId',
    title: 'Data Set ID',
  },
  {
    name: 'dataSetName',
    title: 'Data Set Name',
  },
  {
    name: 'dataSetCollectionDate',
    title: 'Data Set Collection Date',
    type: 'date',
    filterType: 'text',
  },
  {
    name: 'analysisType',
    title: 'Analysis Type',
    getValue: (row) => (row.format === '.csv' ? 'IUTT BNIT' : 'Regular'),
  },
  {
    name: 'facilitator',
    title: 'Facilitator',
  },
  {
    name: 'tags',
    title: 'Tags',
    getValue: (row) => row.tags && row.tags.map(one => one.value).join(' '),
  },
];

const AnalyzeLandingPage = () => {
  const [analyses, setAnalyses] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory()

  useEffect(() => {
    const getArchiveTabelData = async () => {
      try {
        const res = await fetch(`/api/analyses/takeLatest?${qs.stringify({ take: 5 })}`, { credentials: 'include' })
        if (res.status === 200) {
          const data = await res.json();
          setAnalyses(data)
        } else {
          throw new Error("Data loading error!")
        }
      } catch (error) {
        alert(error.message)
      }
      setIsLoading(false)
    }
    getArchiveTabelData()
  }, []);

  return (
    <div className="analyze-landing-page container py-4">
      <div className="analyze-landing-page__header">
        Welcome
      </div>
      <img className="analyze-landing-page__analyze-image" src={AnalyzeImage} alt=""/>
      <div className="analyze-landing-page__desc-text">Analyze qualitative data for emotional tones.</div>
      <div className="analyze-landing-page__buttons-block">
        <div className="used-to-think-button" onClick={() => history.push("/analyze/used-to-think")}>
          <div className="used-to-think-button__text">
            <div className="used-to-think-button__text__first-line">
              For <p className="first-line__red-text">"I used to think..."</p>
            </div>
            <div className="used-to-think-button__text__second-line">analysis, start here</div>
          </div>
        </div>
        <div className="regular-text-button" onClick={() => history.push("/analyze/regular-text")}>
          <div className="regular-text-button__text">
            <div className="regular-text-button__text__first-line">
              For <p className="first-line__blue-text">regular text</p>
            </div>
            <p className="regular-text-button__text__second-line">analysis, start here</p>
          </div>
        </div>
      </div>
      <div className="analyze-landing-page__tabel-block">
        <div className="tabel-block__header">Your Analysis Archive</div>
        <div className="col-sm mb-3">
          <div className="btn-group float-right">
            <Link
              to={
                selected.length === 0
                  ? '#nowhere'
                  : selected.length === 1
                  ? `/analyses/${selected[0]}`
                  : `/analyses/aggregate?ids=${selected.join()}`
              }
              className={classNames('btn', 'btn-primary', {
                disabled: selected.length === 0,
              })}
            >
              View
            </Link>
            <ShareAnalysesModal analysisIds={selected} />
          </div>
        </div>
        <Tabel
          columns={tableColumns}
          rows={analyses}
          selected={selected}
          onSelect={setSelected}
          isLoading={isLoading}
          showFilters={false}
        />
      </div>
    </div>
  )
}

export default AnalyzeLandingPage