import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  main: {
    padding: '30px 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    height: 120,
    marginTop: 80,
    marginBottom: 30,
  },
  form: {
    marginTop: 30,
    marginBotton: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .col-sm': {
      minWidth: 300,
    }
  },
  button: {
    background: 'none',
    color: 'inherit',
    padding: '8px 20px',
    border: '2px solid #ebeaed',
    borderRadius: '22px',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  modeLabel: {
    fontSize: '.75rem',
    marginLeft: 8,
  },
  googleIcon: {
    height: 50,
  },
});

function LoginPage({ onUserChange }) {
  const classes = useStyles();

  const [mode, setMode] = useState('signin');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState(null);

  const handleSignIn = event => {
    event.preventDefault();
    setError(null);
    fetch('/api/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
      credentials: 'include',
    })
      .then(async res => {
        if (res.status === 200) {
          const body = await res.json();
          onUserChange(body);
        } else {
          const text = await res.text();
          setError(text || res.statusText);
        }
      })
      .catch(error => setError(error.message));
  };

  const handleSignUp = event => {
    event.preventDefault();
    fetch('/api/auth/signup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password, firstName, lastName }),
      credentials: 'include',
    })
      .then(async res => {
        if (res.status === 200) {
          const body = await res.json();
          onUserChange(body);
        } else {
          const text = await res.text();
          setError(text || res.statusText);
        }
      })
      .catch(error => setError(error.message));
  };

  return (
    <main className={classes.main}>
      <img src="/logo-red.png" alt="People Rocket" className={classes.logo} />
      <h2>Tonal Analysis Tool</h2>

      <form onSubmit={mode === 'signin' ? handleSignIn : handleSignUp} className={classes.form}>
        <div className="row">
          <div className="col-sm">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
          />
        </div>
        {mode === 'signin' && (
          <>
            <div className="form-group">
              <button type="submit" className={classes.button}>
                Sign in
              </button>
              <span className={classes.modeLabel}>
                New around here? <a href="#sign-up" onClick={() => setMode('signup')}>Sign up</a>
              </span>
            </div>
          </>
        )}
        </div>

        {mode === 'signup' && (
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="lastName"
                className="form-control"
                id="lastName"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className={classes.button}>
                Sign up
              </button>
              <span className={classes.modeLabel}>
                Have an account? <a href="#sign-in" onClick={() => setMode('signin')}>Sign in</a>
              </span>
            </div>
          </div>
        )}
        </div>
        {error && (
          <div class="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </form>

      — or —

      <a
        href={
          `${process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''}/api/auth/google`
        }
      >
        <img src="/sign-in-with-google.png" alt="Sign in with Google" className={classes.googleIcon} />
      </a>
    </main>
  );
}

export default LoginPage;
