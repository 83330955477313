// /*global google*/
import React, { useState, useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import UsedToThink from './components/usedToThink';
import AutoComplete from '../../components/AutoComplete';
import 'react-datepicker/dist/react-datepicker.css';
// import GooglePicker from './GooglePicker';

const useStyles = createUseStyles({
  form: {
    maxWidth: 900,
    '& .col-sm': {
      borderLeft: '3px solid #b6203a',
    },
  },
  btnRounded: {
    padding: '8px 20px',
    borderRadius: '22px',
  },
  datePicker: {
    display: 'block',
  },
});

function NewAnalysisPage() {
  const classes = useStyles();

  const [analyzeType, setAnalyzeType] = useState();
  const [clientOrCohortName, setClientOrCohortName] = useState('');
  const [dataSetId, setDataSetId] = useState('');
  const [dataSetName, setDataSetName] = useState('');
  const [dataSetCollectionDate, setDataSetCollectionDate] = useState('');
  const [facilitator, setFacilitator] = useState('');
  const [tags, setTags] = useState('');

  const [rawText, setRawText] = useState('');

  const fileInput = useRef(null);
  const [files, setFiles] = useState([]);

  const [error, setError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [analysis, setAnalysis] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.indexOf('regular-text') !== -1) {
      setAnalyzeType('regular-text');
    } else {
      setAnalyzeType('used-to-think');
    }
  }, []);

  function blobToFile(blob, fileName = 'aggregatedFile.csv') {
    const file = new File([blob], fileName);
    return file;
  }

  const aggregatedFiles = async () => {
    let resultBuffer = [];
    for (const file of files) {
      const buffer = await file.arrayBuffer();
      const uintBuffer = new Uint8Array(buffer);
      resultBuffer = [...resultBuffer, ...uintBuffer];
    }
    const resultUintBuffer = new Uint8Array(resultBuffer);
    const blob = new Blob([resultUintBuffer], { type: 'text/csv' });

    return blobToFile(blob);
  };

  // const handleGoogleFileChange = (data, oauthToken) => {
  //   if (data.action === google.picker.Action.PICKED && data.docs[0]) {
  //     const url = `https://www.googleapis.com/drive/v2/files/${data.docs[0].id}`;
  //     const options = { headers: { Authorization: `Bearer ${oauthToken}` } };
  //     fetch(url, options)
  //       .then(response => response.json())
  //       .then(response => fetch(response.downloadUrl, options))
  //       .then(response => response.blob())
  //       .then(blob => {
  //         fileInput.current.value = '';
  //         setFile(new File([blob], data.docs[0].name))
  //       })
  //       .catch(error => setError(error.message));
  //   }
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!rawText && files.length === 0) {
      setError('Please, select a file or text.');
      return;
    }

    const formData = new FormData();

    let aggregateFile;
    if (analyzeType === 'regular-text') {
      if (rawText) formData.append('rawText', rawText);
    } else {
      try {
        aggregateFile = await aggregatedFiles();
        if (aggregateFile) formData.append('file', aggregateFile);
      } catch (error) {
        console.log(error);
        alert('Wrong file was detected!');
        return;
      }
    }

    formData.append('clientOrCohortName', clientOrCohortName);
    formData.append('dataSetId', dataSetId);
    formData.append('dataSetName', dataSetName);
    formData.append(
      'dataSetCollectionDate',
      dataSetCollectionDate.toISOString()
    );
    if (facilitator) formData.append('facilitator', facilitator);
    if (tags) formData.append('tags', tags);

    setError(null);
    setIsSubmitting(true);
    fetch('/api/analyses', {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          setAnalysis(body);
        } else {
          setError(res.statusText);
        }
      })
      .catch((error) => setError(error.message))
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleCancel = () => {
    setFacilitator('');
    setDataSetId('');
    setDataSetName('');
    setDataSetCollectionDate('');
    setClientOrCohortName('');
    setTags('');
    setRawText('');
    setFiles(null);
    setError(null);
    fileInput.current.value = '';
    history.push('/')
  };

  if (analysis) {
    return <Redirect to={`/analyses/${analysis.id}`} />;
  }

  return (
    <div className="container py-4">
      <h2 className="mb-3">Analyze</h2>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className={classes.form}
      >
        <div className="row">
          <div className="col-sm">
            <h3>Info</h3>
            <div className="form-group mb-2">
              <label
                htmlFor="clientOrCohortName"
                className="col-form-label-sm mb-0"
              >
                Client / Cohort Name *
              </label>
              <AutoComplete
                sugValue={clientOrCohortName}
                setSugValue={setClientOrCohortName}
                fieldName="clientOrCohortName"
                inputProps={{
                  className: 'form-control form-control-sm',
                  placeholder: 'ex. Mozilla',
                  required: true
                }}
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="dataSetId" className="col-form-label-sm mb-0">
                Data Set ID *
              </label>
              <AutoComplete
                sugValue={dataSetId}
                setSugValue={setDataSetId}
                fieldName="dataSetId"
                inputProps={{
                  className: 'form-control form-control-sm',
                  placeholder: 'ex. 2407-04-01',
                  required: true
                }}
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="dataSetName" className="col-form-label-sm mb-0">
                Data Set Name *
              </label>
              <AutoComplete
                sugValue={dataSetName}
                setSugValue={setDataSetName}
                fieldName="dataSetName"
                inputProps={{
                  className: 'form-control form-control-sm',
                  placeholder: 'ex. Tech Advising',
                  required: true
                }}
              />
            </div>
            <div className="form-group mb-2">
              <label
                htmlFor="dataSetCollectionDate"
                className="col-form-label-sm mb-0"
              >
                Data Set Collection Date *
              </label>
              <DatePicker
                selected={dataSetCollectionDate}
                onChange={setDataSetCollectionDate}
                required
                dateFormat="MM.dd.yyyy"
                placeholderText="MM.DD.YYYY"
                popperPlacement="bottom-middle"
                id="dataSetCollectionDate"
                className="form-control form-control-sm"
                wrapperClassName={classes.datePicker}
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="facilitator" className="col-form-label-sm mb-0">
                Facilitator
              </label>
              <AutoComplete
                sugValue={facilitator}
                setSugValue={setFacilitator}
                fieldName="facilitator"
                inputProps={{
                  className: 'form-control form-control-sm',
                  placeholder: 'ex. Allan Gulley',
                }}
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="tags" className="col-form-label-sm mb-0">
                Tags
              </label>
              <AutoComplete
                sugValue={tags}
                setSugValue={setTags}
                fieldName="value"
                multiple
                inputProps={{
                  className: 'form-control form-control-sm',
                  placeholder:
                    'ex. #innovation-ish #learningscience #technology',
                  pattern: '^#[a-zA-Z0-9-]+( #[a-zA-Z0-9-]+){0,9}$',
                }}
              />
              <small className="form-text text-muted">
                up to 10 tags allowed
              </small>
            </div>
          </div>
          <div className="col-sm">
            <h3>Data</h3>
            {analyzeType === 'regular-text' ? (
              <div className="form-group">
                <label htmlFor="text">Text</label>
                <textarea
                  ref={fileInput}
                  value={rawText}
                  onChange={(event) => setRawText(event.target.value)}
                  id="text"
                  className="form-control"
                  rows="3"
                />
              </div>
            ) : (
              <UsedToThink
                fileInput={fileInput}
                files={files}
                setFiles={setFiles}
              />
            )}
            <div className="form-group mt-3">
              <button
                type="submit"
                className={`btn btn-primary mr-2 ${classes.btnRounded}`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className={`btn btn-secondary ${classes.btnRounded}`}
                disabled={isSubmitting}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {error && (
          <div class="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </form>
    </div>
  );
}

export default NewAnalysisPage;
