import React, { useEffect, useState } from 'react';
import Table from './Table';

const tableColumns = [
  {
    name: 'id',
    title: 'ID',
    type: 'number',
    filterType: 'string',
    isEditable: false,
    isInitializable: false,
  },
  {
    name: 'firstName',
    title: 'First Name',
    isEditable: false,
  },
  {
    name: 'lastName',
    title: 'Last Name',
    isEditable: false,
  },
  {
    name: 'email',
    title: 'Email',
    type: 'email',
    filterType: 'text',
    isEditable: false,
  },
  {
    name: 'password',
    title: 'Password',
    type: 'password',
    isFilterable: false,
    isEditable: false,
  },
  {
    name: 'googleId',
    title: 'Google ID',
    isEditable: false,
    isInitializable: false,
  },
  {
    name: 'isEnabled',
    title: 'Enabled',
    type: 'boolean',
  },
  {
    name: 'isAdmin',
    title: 'Admin',
    type: 'boolean',
  },
];

function AdminPage() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch('/api/users')
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          setUsers(body);
          setIsLoading(false);
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((error) => alert(error.message));
  }, []);

  const handleAdd = (user) => {
    return fetch('/api/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
      credentials: 'include',
    })
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          setUsers((users) => [body, ...users]);
          return true;
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((error) => {
        alert(error.message);
        return false;
      });
  };

  const handleEdit = async (user) => {
    const original = users.find((one) => one.id === user.id);
    const difference = {};
    for (const columnName of Object.keys(user)) {
      if (user[columnName] !== original[columnName])
        difference[columnName] = user[columnName];
    }
    return fetch(`/api/users/${user.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(difference),
      credentials: 'include',
    })
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          setUsers((users) =>
            users.map((one) => (one.id === user.id ? body : one))
          );
          return true;
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((error) => {
        alert(error.message);
        return false;
      });
  };

  const handleDelete = (user) => {
    return fetch(`/api/users/${user.id}`, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then((res) => {
        if (res.status === 200) {
          setUsers((users) => users.filter((one) => one.id !== user.id));
          return true;
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((error) => {
        alert(error.message);
        return false;
      });
  };

  return (
    <div className="container py-4">
      <div className="row">
        <h2 className="col-sm mb-3">Admin</h2>
      </div>
      <Table
        columns={tableColumns}
        rows={users}
        isLoading={isLoading}
        onRowAdd={handleAdd}
        onRowEdit={handleEdit}
        onRowDelete={handleDelete}
      />
    </div>
  );
}

export default AdminPage;
