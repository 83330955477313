import React, { useState, useMemo, useCallback } from 'react';
import Autosuggest from 'react-autosuggest';
import './styles/index.scss';
import qs from 'qs';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const AutoComplete = ({
  sugValue,
  setSugValue,
  fieldName = 'clientOrCohortName',
  inputProps,
  multiple = false,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const mulipleOnChange = (newValue, method) => {
    if (method === 'type') return setSugValue(newValue);
    const spaceIndex = sugValue.lastIndexOf(' ');
    if (spaceIndex !== -1) {
      setSugValue((value) => value.substring(0, spaceIndex + 1) + newValue);
    } else {
      setSugValue(newValue);
    }
  };

  const standartOnChange = (newValue, method) => {
    setSugValue(newValue);
  };

  const getAutocompleteValues = async (value) => {
    if (!value || value.length === 0) return;
    let strToAutocomplete = value;
    if (multiple) {
      const spaceIndex = value.lastIndexOf(' ');
      if (spaceIndex !== -1) {
        strToAutocomplete = strToAutocomplete.slice(
          spaceIndex,
          strToAutocomplete.length
        );
      }
    }
    const apiUrl = multiple
      ? `/api/analyses/get-multiple-autocomplete-filelds`
      : `/api/analyses/get-autocomplete-filelds`;
    try {
      const res = await fetch(
        `${apiUrl}?${qs.stringify({
          field: fieldName,
          fieldValue: strToAutocomplete,
        })}`,
        { credentials: 'include' }
      );
      if (res.status === 200) {
        const data = await res.json();
        setSuggestions(data);
      } else {
        throw new Error('Data loading error!');
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const debouncedFunction = useMemo(
    () => debounce(getAutocompleteValues, 250),
    []
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedFunction(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = useCallback(
    (event, { newValue, method }) => {
      multiple
        ? mulipleOnChange(newValue, method)
        : standartOnChange(newValue, method);
    },
    [sugValue, multiple]
  );

  const getSuggestionValue = (suggestion) => suggestion[fieldName];

  const renderSuggestion = (suggestion) => <div>{suggestion[fieldName]}</div>;

  const curInputProps = useMemo(() => {
    return {
      ...inputProps,
      value: sugValue,
      onChange,
      className: `cust-auto-complete ${inputProps ? inputProps.className : ''}`,
      };
  }, [inputProps, onChange, sugValue]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={curInputProps}
    />
  );
};

export default AutoComplete;
