import React, { useState, useEffect, useRef } from 'react';
import { Chart, Bar, defaults as chartDefaults } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import generateCsv from 'csv-stringify/lib/sync';
import Loading from './Loading';
import ErrorAlert from './ErrorAlert';
import whiteChartBackground from '../utils/chartjs-white-background';
import EMOTIONS from './emotions';
import ShareAnalysesModal from './ShareAnalysesModal';
import DownloadModal from './DownloadModal'

const HEADERS = EMOTIONS.map((one) => one.header);

Chart.pluginService.register(whiteChartBackground);
chartDefaults.global.defaultFontColor = 'black';

const increase = (a, b, d) => (b < a ? undefined : d);
const decrease = (a, b, d) => (a < b ? undefined : d);

const reportToTable = (report) => {
  const tableHeader = [
    '',
    'IUTT',
    ...HEADERS,
    'BNIT',
    ...HEADERS,
    'Delta',
    ...HEADERS,
  ];
  const tableBody = [];
  for (let i = 0; i < report.statements.length; i++) {
    tableBody.push([
      '',
      report.statements[i][0],
      ...EMOTIONS.map((emotion) => report.vectors[i][0][emotion.key]),
      report.statements[i][1],
      ...EMOTIONS.map((emotion) => report.vectors[i][1][emotion.key]),
      '',
      ...EMOTIONS.map((emotion) => report.deltaVectors[i][emotion.key]),
    ]);
  }
  const tableFooter = [
    'Average',
    '',
    ...EMOTIONS.map((emotion) => report.averageVectors[0][emotion.key]),
    '',
    ...EMOTIONS.map((emotion) => report.averageVectors[1][emotion.key]),
    '',
    ...EMOTIONS.map((emotion) => report.averageDeltaVector[emotion.key]),
  ];
  const table = [tableHeader, ...tableBody, tableFooter];
  return table;
};


function SplitChart({ analysisIds, title, fileName }) {
  const [report, setReport] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    fetch('/api/analyze-tone', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ analysisIds }),
      credentials: 'include',
    })
      .then(async (res) => {
        if (res.status === 200) {
          const body = await res.json();
          setReport(body);
        } else {
          const text = await res.text();
          setError(text || res.statusText);
        }
      })
      .catch((error) => setError(error.message))
      .finally(() => setIsAnalyzing(false));
  }, [analysisIds]);

  const chartRef = useRef(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);

  const handleChartChange = () => {
    if (!imageDataUrl && chartRef.current) {
      const dataUrl = chartRef.current.chartInstance.canvas.toDataURL(
        'image/jpeg'
      );
      setImageDataUrl(dataUrl);
    }
  };

  const [csvUrl, setCsvUrl] = useState(null);

  useEffect(() => {
    if (report) {
      if (csvUrl) window.URL.revokeObjectURL(csvUrl);
      const fileData = generateCsv(reportToTable(report));
      const file = new Blob([fileData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(file);
      setCsvUrl(url);
    }
    return () => {
      if (csvUrl) window.URL.revokeObjectURL(csvUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  if (isAnalyzing) return <Loading />;
  if (error) return <ErrorAlert message={error} />;

  return (
    <>
      <div className="col-sm mb-3">
        <div className="btn-group float-right">
          <DownloadModal analysisIds={analysisIds} fileName={fileName} csvUrl={csvUrl} imageDataUrl={imageDataUrl}/>
          <ShareAnalysesModal analysisIds={analysisIds} />
        </div>
      </div>
      <div className="chart">
        <Bar
          data={{
            labels: EMOTIONS.map((one) => one.header),
            datasets: [
              {
                stack: 'Stack 0',
                label: 'Before',
                backgroundColor: 'rgba(182, 31, 58, 0.75)',
                borderColor: 'rgba(182, 31, 58, 1)',
                fontColor: 'black',
                borderWidth: 2,
                data: EMOTIONS.map((one) => report.averageVectors[0][one.key]),
              },
              {
                stack: 'Stack 1',
                label: 'After',
                backgroundColor: 'rgba(13, 131, 186, 0.75)',
                borderColor: 'rgba(13, 131, 186, 1)',
                borderWidth: 2,
                data: EMOTIONS.map((one) => report.averageVectors[1][one.key]),
              },
              {
                stack: 'Stack 0',
                label: 'Increase',
                backgroundColor: 'rgba(87, 87, 91, 0.2)',
                borderColor: 'rgba(87, 87, 91, 0.3)',
                borderWidth: 2,
                data: EMOTIONS.map((one) =>
                  increase(
                    report.averageVectors[0][one.key],
                    report.averageVectors[1][one.key],
                    report.averageDeltaVector[one.key]
                  )
                ),
              },
              {
                stack: 'Stack 1',
                label: 'Decrease',
                backgroundColor: 'rgba(159, 159, 165, 0.2)',
                borderColor: 'rgba(159, 159, 165, 0.3)',
                borderWidth: 2,
                data: EMOTIONS.map((one) =>
                  decrease(
                    report.averageVectors[0][one.key],
                    report.averageVectors[1][one.key],
                    report.averageDeltaVector[one.key]
                  )
                ),
              },
            ],
          }}
          options={{
            responsive: true,
            aspectRatio: 1.91,
            legend: {
              display: true,
              labels: {
                fontColor: 'black',
              },
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 1,
                    min: 0,
                    stepSize: 0.2,
                  },
                },
              ],
            },
            animation: {
              onComplete: handleChartChange,
            },
          }}
          ref={chartRef}
        />
      </div>
    </>
  );
}

export default SplitChart;
